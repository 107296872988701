
  export default {
    data: () => ({
      loading: true,
      dataLoaded: false
    }),
     head () {
        return {
            bodyAttrs: {
                class: this.dataLoaded ? '' : 'overflow-hidden'
            }
        }
    },
    methods: {
      start() {
        this.loading = true;
        this.dataLoaded = false;
      },
      finish() {
        this.loading = false;
        this.dataLoaded = true;
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.$nuxt.$loading.start();
        setTimeout(() => this.$nuxt.$loading.finish(),650)
      });

    }
  };

